// 公用的一些方法
import { baseApi } from '@/config'; // 根据环境不同引入不同api地址
import { getToken } from "@/utils/auth";
import axios from 'axios';
import { encrypt } from '@/utils/crypto';
import { Message } from 'element-ui'
import { cloneDeep } from 'lodash';


export async function exportExcel(data, name,url) {
  let tempData =  {
    ...data,
    time:new Date().getTime(),
  }
  let obj = {
    ...tempData,
    sign: encrypt(JSON.stringify(tempData)),
  }
  // let res = await axios.post(baseApi+ url,obj,{ headers: { Authorization: getToken() } }, { responseType: "arraybuffer" },);
  // const res_copy= cloneDeep(res)
  //   if (!res) return;
  //   try {
  //     //如果JSON.parse(enc.decode(new Uint8Array(res.data)))不报错，说明后台返回的是json对象，则弹框提示
  //     //如果JSON.parse(enc.decode(new Uint8Array(res.data)))报错，说明返回的是文件流，进入catch，下载文件
  //     let enc = new TextDecoder('utf-8')
  //     let resl = JSON.parse(enc.decode(new Uint8Array(res_copy.data))) //转化成json对象
  //     if (resl.code ==='0') {
  //       Message.error(resl.msg)
  //     }
  //   } catch (err) {
  //     console.log(res,'res=');
  //     const blob = new Blob([res.data],{
  //       type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //     })
  //     let href = window.URL.createObjectURL(blob)
  //     let a = document.createElement('a')
  //     a.style.display = 'none'
  //     a.href = href
  //     a.setAttribute("download", name)
  //     document.body.appendChild(a)
  //     a.click()
      
  //   }

  axios({
    method: 'post',
    url: baseApi+ url,
    data: obj,
    responseType: "arraybuffer" ,
    headers:{
      Authorization:getToken()
    }
  }).then((res) => {
    console.log(res,';res');
    if (res.status == 200) {
      const blob = new Blob([res.data],{
        type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      let href = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.setAttribute("download", name)
      document.body.appendChild(a)
      a.click()
    } else {
      console.log(err);
      Message.error(err)
    }
    // a.remove()
  }).catch(err => {
    console.log(err,'err');
    Message.error(err)
  })

}
// 权限菜单目前三层
export function handlePageData(arr) {
  if (!Array.isArray(arr))  return
  return arr?.map(item => {
    if (item.children && item.children.length) {
      item['prePage'] = item.children?.filter?.(el => {
        if (el?.children && el.children.length) {
          el['prePage'] = el.children?.filter?.(x => {
            if (x.type == 3) {
              return x
            }
          })
        }
        if (el.type == 3) {
          return el
        }
        
      })
      return item
    }
  })
}

// 处理权限数据，页面不展示
export function displayPage(arr) {
  if (!Array.isArray(arr))  return
  if (arr && arr.length) {
    return arr?.filter(item => {
      
      if (item.children && item.children.length) {
        item.children = displayPage(item.children)
      }
      if (item.type ==1) {
        return item
      }
      
    })
  }

}
//字符串转首字母大写
export function firstToUpper(str){
  return str.trim().toLowerCase().replace(str[0],str[0].toUpperCase());
};
// 字符串 超出隐藏
export function delStrLen(str,len) {
  if (str.length > len) {
    return str.slice(0,len)+'...'
  } else {
    return str
  }
}
// 数组去重
export function uniqueArrFun(arr){
  let map = new Set(arr);
  return [...map]

}

/**
 * 数组中包含某个字符
 * @param {arr} 被判断数组
 * @param {str} 判断的字符串
 * @returns {Boolean} 
 */
export function arrHasStr (arr,str) {
  if (arr?.includes(str)) {
    return true
  }else {
    return false
  }
}