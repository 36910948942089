import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import { uniqueArrFun } from '@/utils/utils'

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ['/login','/403'];// no redirect whitelist

router.beforeEach((to,from,next) =>{
  NProgress.start();
  let hasToekn = getToken();
  // console.log(store.state.permission.addRoutes,'permision.js--------');
  if(hasToekn){
    if (!store.state.permission.addRoutes?.length && store.state.permission.hasPerms) {
      store.dispatch('permission/generateRoutes').then(routers => {
        // console.log(routers,'routers-----',to);
        if (!routers.length) {
          next('/403')
        } else {
          routers?.forEach(item => {
            router.addRoute(item)
          })
          next({...to,replace:true})
        }
      })
      
    } else {
      // console.log(123, store.state.permission.addRoutes);
      const tempArr = []
      const allRoutesPath = store.state.permission.addRoutes?.map(el => {
        if (el.children) {
          el.children?.forEach(exx => {
            if (exx.path) {
              tempArr.push(exx.path)
            }
          })
          tempArr.push(el.path)
        }
        return el.path
      })
      // console.log(allRoutesPath,'allRoutesPath');
      const resArr = uniqueArrFun(tempArr)
      if (resArr.includes(to.path)) {
        next();
      } else {
        next(resArr[0])
      }
      
    }
    NProgress.done();
  }else{
    // 未登录可以访问白名单页面(登录页面)
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      console.log('login');
      next(`/login`);
      NProgress.done();
    }
  }
});


router.afterEach(() => {
  NProgress.done();
});
