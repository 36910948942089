import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

//解决vue路由重复导航错误
//获取原型对象上的push函数
const orginalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  //修改原型对象中的push方法
  return orginalPush.call(this, location).catch(err => err)
};


/* Layout */
export const Layout = () =>import('@/layout/index.vue');

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404.vue'),
    hidden: true
  },
  {
    path: '/403',
    component: () => import('@/views/errorPage/403.vue'),
    hidden: true
  },
  // {
  //   path:'/',
  //   component: Layout,
  //   children:[
  //     {
  //       path: '/',
  //       component: () => import('@/views/stores/index.vue'),
  //       name: 'Stores',
  //       meta: { title: '门店管理', icon: 'homepage', affix: true }
  //     },
  //   ]
  // },
  // {
  //   path:'/merchants',
  //   component: Layout,
  //   children:[
  //     {
  //       path: '/merchants',
  //       component: () => import('@/views/merchants/index.vue'),
  //       name: 'Merchants',
  //       meta: { title: '商家管理', icon: 'homepage', affix: true }
  //     },
  //   ]
  // },
  // {
  //   path:'/version',
  //   component: Layout,
  //   children:[
  //     {
  //       path: '/version',
  //       component: () => import('@/views/version/index.vue'),
  //       name: 'Version',
  //       meta: { title: '版本管理', icon: 'homepage', affix: true }
  //     },
  //   ]
  // },
  // {
  //   path:'/comMaterials',
  //   component: Layout,
  //   children:[
  //     {
  //       path: '/comMaterials',
  //       component: () => import('@/views/comMaterials/index.vue'),
  //       name: 'ComMaterials',
  //       meta: { title: '公共素材管理', icon: 'homepage', affix: true }
  //     },
  //   ]
  // },
  // {
  //   path:'/menuManagement',
  //   component: Layout,
  //   children:[
  //     {
  //       path: '/menuManagement',
  //       component: () => import('@/views/menuManagement'),
  //       name: 'MenuManagement',
  //       meta: { title: '菜单管理', icon: 'homepage', affix: true }
  //     },
  //   ]
  // },
  // {
  //   path:'/sms',
  //   component: Layout,
  //   redirect: '/smsGiveScheme',
  //   meta: { title: '短信', icon: 'homepage', affix: true },
  //   children:[
  //     {
  //       path: '/smsGiveScheme',
  //       component: () => import('@/views/sms/smsGiveScheme/index.vue'),
  //       name: 'SmsGiveScheme',
  //       meta: { title: '短信充赠方案', icon: 'homepage', affix: true }
  //     },
  //     {
  //       path: '/smsTemplate',
  //       component: () => import('@/views/sms/smsTemplate/index.vue'),
  //       name: 'SmsTemplate',
  //       meta: { title: '短信模板', icon: 'homepage', affix: true }
  //     },
  //     {
  //       path: '/smsGiveReport',
  //       component: () => import('@/views/sms/smsGiveReport/index.vue'),
  //       name: 'SmsGiveReport',
  //       meta: { title: '短信充赠报表', icon: 'homepage', affix: true }
  //     },
  //     {
  //       path: '/smsSendReport',
  //       component: () => import('@/views/sms/smsSendReport'),
  //       name: 'SmsSendReport',
  //       meta: { title: '短信发送报表', icon: 'homepage', affix: true }
  //     },
  //   ]
  // },
  // {
  //   path:'/sysseting',
  //   component: Layout,
  //   redirect: '/presetData',
  //   meta: { title: '系统设置', icon: 'homepage', affix: true },
  //   children:[
  //     {
  //       path: '/presetData',
  //       component: () => import('@/views/sysseting/presetData'),
  //       name: 'PresetData',
  //       meta: { title: '预设数据', icon: 'homepage', affix: true }
  //     },
  //     {
  //       path: '/help',
  //       component: () => import('@/views/sysseting/help'),
  //       name: 'Help',
  //       meta: { title: '掌上云通常见问题', icon: 'homepage', affix: true }
  //     },
  //     {
  //       path: '/about',
  //       component: () => import('@/views/sysseting/about'),
  //       name: 'About',
  //       meta: { title: '关于我们', icon: 'homepage', affix: true }
  //     },
  //   ]
  // }, {
  //   path:'/statement',
  //   component: Layout,
  //   redirect: '/statementInfo',
  //   meta: { title: '报表', icon: 'homepage', affix: true },
  //   children:[
  //     {
  //       path: '/statementInfo',
  //       component: () => import('@/views/statement/statementInfo'),
  //       name: 'statementInfo',
  //       meta: { title: '门店运营情况', icon: 'homepage', affix: true }
  //     }
  //   ]
  // }, {
  //   path:'/accountManage',
  //   component: Layout,
  //   redirect: '/account',
  //   meta: { title: '账号管理', icon: 'homepage', affix: true },
  //   children:[
  //     {
  //       path: '/account',
  //       component: () => import('@/views/account/index'),
  //       name: 'account',
  //       meta: { title: '账号管理', icon: 'homepage', affix: true }
  //     },
  //     {
  //       path: '/job',
  //       component: () => import('@/views/account/job'), 
  //       name: 'job',
  //       meta: { title: '职务管理', icon: 'homepage', affix: true }
  //     }
  //   ]
  // }

];

export const asyncRoutes = [

];

const createRouter = () => new Router({
  //mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
});

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher // reset router
}

export default router
