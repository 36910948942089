import {getToken, removeToken, setToken} from '@/utils/auth'
import {resetRouter} from '@/router'
import {login, userInfo} from '@/api/login'

const state = {
  token: getToken(),
  name: localStorage.getItem('platformUser') ? JSON.parse(localStorage.getItem('platformUser')).name : '',
  avatar: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
  introduction: '',
  roles: ['admin'],
  aliyun: null,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ALIYUN: (state, aliyun) => {
    state.aliyun = aliyun
  },
  DEL_USERINFO: (state) => {
    state.name = '';
    state.avatar = '';
    localStorage.removeItem('platformUser')
  }
};

const actions = {
  login({commit}, userInfo) {
    const {phone, password} = userInfo;
    return new Promise((resolve, reject) => {
      login({phone: phone, password: password,}).then(response => {
        const {data} = response;
        const token = 'Bearer ' + data.access_token;
        commit('SET_TOKEN', token);
        setToken(token);
        resolve();
      }).catch(error => {
        reject(error)
      })
    })
  },

  getUserinfo({commit, state}) {
    return new Promise((resolve, reject) => {
      userInfo()
        .then((res) => {
          const {data} = res;
          commit('SET_NAME', data.name);
          localStorage.setItem('platformUser', JSON.stringify(data));
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  logout({commit, state, dispatch}) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      commit('DEL_USERINFO');
      removeToken();
      resetRouter();
      dispatch('tagsView/delAllViews', null, {root: true});
      resolve()
    })
  },

  resetToken({commit}) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      commit('DEL_USERINFO');
      removeToken();
      resolve()
    })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
