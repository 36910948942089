import api from './api'
import request from '../utils/request'


export function uploadSign() {
  return request({
    url: api.upload_sign,
    method:'post'
  })
}

// 获取所有门店 /common/shop/allShopList
export function getStores(data) {
  return request({
    url: 'platform/shop/allShopList',
    method:'post',
    data
  })
}
export function getPriceList() {
  return request({
    url:'common/notauth/goodspricingschemes',
    method:'POST',
    // data
  })
}
// 获取员工权限
export function getPermissionsApi(data) {
  return request({
    url:'/platform/platformuser/menusPurview',
    method:'POST',
    data
  })
}