module.exports = {
  title: '平台管理系统',

  showSettings: false,

  tagsView: true,

  fixedHeader: false,

  sidebarLogo: false,

};
